.container {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-size: 30px;
  color: #000000;
}

.bold {
  font-size: 50px;
  font-weight: bold;
}
