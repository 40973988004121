.container {
  display: flex;
  align-items: center;
}

.dropdown {
  height: 20px;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.name {
  margin: 0 5px;
}
.marginButton {
  margin-left: 39%;
}

.count {
  margin-left: 40%;
  background-color: #1890ff;
  text-align: center;
  width: 100px;
  height: 32px;
  display: inline-block;
}
.passwordButton {
  margin-left: 65%;
  margin-top: 5%;
}

.ftpForm {
  margin-top: 20px;
  .ftpFormItem {
    margin-bottom: 5px;
  }
}
