.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 150px;
}

.logo {
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}
