.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 30px;
  padding-bottom: 10px;
  background-color: #fff;
  width: 460px;
  height: 380px;
  border-radius: 10px;
}

.operation {
  padding: 30px 10px 10px 10px;
}

.hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.visible {
  opacity: 1;
  pointer-events: auto;
}

.alignMiddle {
  line-height: 36px;
}
