.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: #fff;
  width: 420px;
  height: 340px;
  border-radius: 10px;
}

.hidden {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

.visible {
  opacity: 1;
  pointer-events: auto;
}
