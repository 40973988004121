.container {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
}

.logo {
  margin: 10px 0;
  line-height: 40px;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
}

.logo2 {
  width: 40px;
  object-fit: contain;
  margin: 10px 20px;
}

.rightLayout {
  min-width: 750px;
  .header {
    padding-left: 20px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headerLeft {
      display: flex;
      align-items: center;
    }
    .headerRight {
      display: flex;
      align-items: center;
    }
  }
}

.content {
  position: relative;
  background-color: #ffffff;
  height: 100%;
  overflow: auto;
  margin: 24px 16px;
  margin-right: 0;
  padding: 16px 24px;
}

.hidePaadingContent {
  padding: 0;
}

.scrollView {
  overflow-y: auto;
  min-height: 100vh;
}

.biIcon {
  cursor: pointer;
  font-size: 22px;
  margin-right: 25px;
  color: #000000;
  :hover {
    color: #1890ff;
  }
}
