.avatar {
  left: 10px;
}
.changeButton {
  margin-left: 10px;
}
.readOnly {
  color: rgba(0, 0, 0, 0.25);
  margin-left: 10px;
}
.rowDisplay {
  align-items: center;
  margin-bottom: 15px;
}
.labelName {
  text-align: right;
}
